import { Heading, VStack, Text, Avatar, Link, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useAppStore } from '../../../../stores/AppStore';
import AppLayout from '../../../../components/AppLayout';
import PrimaryButton from '../../../../components/PrimaryButton';
import { useSearchParams } from 'react-router-dom';
// Test 2

export default function StartPage({ arApps }) {
    const [query] = useSearchParams();
    const { t } = useTranslation('app');
    const textSource = query.get("isCollection") ? arApps[0].collection : arApps[0];
    const { author = {} } = textSource;
    const { updateIsLoading, setShowArViewer } = useAppStore.getState();

    const requestWebcamPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            stream.getTracks().forEach(track => track.stop());
        } catch (err) {
            console.error("Error requesting webcam access: ", err);
            alert("Webcam access denied or not available.");
        }
    };

    return (
        <AppLayout gridTemplateRows="50px 2fr 100px">
            <VStack gap='2.5rem'>
                <Heading as='h2' color='#FFF8ED' fontWeight='600' marginBottom='10px'>{textSource.title}</Heading>
                <Text color="white">
                    {textSource.description}
                </Text>
                <VStack spacing={1} align="center">
                    <Text color="white">
                        Made with ❤️ by
                    </Text>
                    <HStack spacing={3} align="center">
                        <Avatar name={author.name} src={``} size="sm" />
                        <Link href="/detail-page" color="white">
                            {author.name}
                        </Link>
                    </HStack>
                </VStack>
                <VStack>
                    <PrimaryButton buttonHandler={async () => {
                        updateIsLoading(true);
                        await requestWebcamPermission();
                        setShowArViewer(true);
                    }}>
                        {t("startButton.text")}
                    </PrimaryButton>
                    <Text color="white">
                        You will need to allow the camera access.
                    </Text>
                </VStack>
            </VStack>
        </AppLayout>
    );
}
