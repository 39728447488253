import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ArifyPage from "./arify/page";
import React from "react";
import { useAppStore } from "../../stores/AppStore";
import LoadingWithStore from "../../components/LoadingWithStore";
import { apiClient } from "../../libs/api-client";

export default function ArPage() {
    const updateIsLoading = useAppStore(state => state.updateIsLoading)
    // const { updateTheme } = useThemeUpdater();
    const navigate = useNavigate()


    const { arAppId } = useParams();
    const [search] = useSearchParams()
    const { data = [], isLoading } = apiClient.scenes.getScenePublic.useQuery({ sceneId: arAppId, collectionId: search.get("isCollection") ? arAppId : undefined })


    React.useEffect(() => {
        if (!isLoading) {
            updateIsLoading(false)
            console.log(data, data.message)
            if (data.message && data.message === "NOT_FOUND"){
                navigate("/404")
            }
            // updateTheme(data.branding.themeColors)
        }

    }, [isLoading])
  
    const arApps = structuredClone(Array.isArray(data) ? data : [data])
    console.log(arApps)


    return <>
        <LoadingWithStore />
        {arApps.length && <ArifyPage arApps={arApps} />}
    </>
}