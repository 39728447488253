
import { Heading, Text, Box, VStack, Link } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppStore } from '../../stores/AppStore';
import AppLayout from '../../components/AppLayout';
import PrimaryButton from '../../components/PrimaryButton';
import AddToHomeScreenButton from '../../components/AddToHomeScreenButton';

export default function HomeScreen() {
    const { t } = useTranslation('app', {keyPrefix: "home"});
    const navigate = useNavigate()
    const setChatShow = useAppStore((state) => state.setChatShow);
    const reset = useAppStore((state) => state.reset);
    useEffect(() => {
        reset();
        setChatShow();
    }, []);

    return (
        <>
            <AppLayout gridTemplateRows="50px 1fr 100px">
                <VStack display='grid' gridTemplateRows='1fr 1fr 1fr'>
                    <div>
                        <Heading as='h2' color='#FFF8ED' fontWeight='300' marginBottom='10px'>
                            {t('heading')} <br /> <b>{t('headingName')}</b>
                        </Heading>
                    </div>
                    <PrimaryButton buttonHandler={() => navigate('/scan-code')}>
                        {t('scanCode')}
                    </PrimaryButton>
                    <Box>
                        <VStack gap='3rem' width='217px' justifyContent='center' margin='0 auto'>
                            <Text
                                color={'#FFF8ED'}
                                textAlign='center'
                                fontSize='14px'
                                fontWeight='400'
                                lineHeight='22px'
                                fontStyle='normal'
                            >
                                {t('orderText')}{' '}<Link color='#7647FF' href='https://relive-ar.com'>{t('orderLink')}</Link>.
                            </Text>
                            <AddToHomeScreenButton />
                        </VStack>
                    </Box>
                </VStack>
            </AppLayout>
        </>
    );
}
