
import { Button, HStack, IconButton, VStack } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react";
import PreviewModal from "./save-assets-modal";
import { MdMovieCreation } from "react-icons/md";
import Timer from "./timer";
import { useMediaCaptureStore } from "../../../../stores/media-capture-store";


export default function MediaCapture() {
    const compositeCanvasRef = useRef(null); // Ref for the composite canvas
    const mediaRecorderRef = useRef(null);
    const recordedBlobsRef = useRef([]);
    const animationFrameIdRef = useRef(null);

    const [timer, setTimer] = useState(null);
    const [isRecordingVideo, setIsRecordingVideo] = useState(false);

    const {
        setShowPreviewModal,
        setImageUrl, setVideoUrl,
    } = useMediaCaptureStore.getState();
    // const [isRecording, toggleRecording] = useMediaCaptureStore(({ isRecording, toggleRecording }) => [isRecording, toggleRecording])

    const takeScreenshot = () => {
        // Capture the A-Frame scene
        const sceneEl = document.querySelector("#scene");
        const canvas = sceneEl.components.screenshot.getCanvas('perspective');

        // Prepare to capture the video feed
        const video = document.getElementById("video"); // Adjust this selector as needed
        if (!video) {
            console.error('Video element not found');
            return;
        }

        // Create an off-screen canvas to combine the images
        const offScreenCanvas = document.createElement('canvas');
        offScreenCanvas.width = video.videoWidth;
        offScreenCanvas.height = video.videoHeight;
        const ctx = offScreenCanvas.getContext('2d');

        // Draw the video feed
        ctx.drawImage(video, 0, 0);

        // Draw the A-Frame scene on top of the video feed
        ctx.drawImage(canvas, 0, 0, offScreenCanvas.width, offScreenCanvas.height);

        // Convert the combined canvas to an image and download it
        offScreenCanvas.toBlob(blob => {
            const url = URL.createObjectURL(blob);
            setImageUrl(url)
            setShowPreviewModal(true)
        }, 'image/png');
    };

    // const handleRecordClick = () => {
    //     if (!isRecording) {
    //         startRecording();
    //     } else {
    //         stopRecording();
    //     }
    //     toggleRecording();
    // };

    const setupMediaRecorder = (stream) => {
        let options
        if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
            options = { mimeType: 'video/webm; codecs=vp9' };
        } else if (MediaRecorder.isTypeSupported('video/webm')) {
            options = { mimeType: 'video/webm' };
        } else if (MediaRecorder.isTypeSupported('video/mp4')) {
            options = { mimeType: 'video/mp4', videoBitsPerSecond: 100000 };
        } else {
            console.error("no suitable mimetype found for this device");
        }
        mediaRecorderRef.current = new MediaRecorder(stream, options);
        mediaRecorderRef.current.ondataavailable = event => {
            if (event.data && event.data.size > 0) {
                recordedBlobsRef.current.push(event.data);
            }
        };
        mediaRecorderRef.current.start(10); // For every 100ms of data

        console.log("Starting recording, stream active:", stream.active);
        console.log("MediaRecorder state before starting:", mediaRecorderRef.current.state);

    };


    const startRecording = () => {
        recordedBlobsRef.current = []; // Reset the recorded blobs for the next recording

        const compositeCanvas = document.createElement('canvas');
        const video = document.getElementById("video"); // Adjust this selector as needed
        compositeCanvas.width = video.videoWidth;
        compositeCanvas.height = video.videoHeight;
        compositeCanvasRef.current = compositeCanvas;

        const compositeCtx = compositeCanvasRef.current.getContext('2d');
        const stream = compositeCanvasRef.current.captureStream(25);


        if (!video) {
            console.error('Video element not found for recording');
            return;
        }
        setupMediaRecorder(stream);
        const scene = document.querySelector("#scene");
        // Pass the necessary variables to the draw function
        const draw = () => {
            if (video && scene && scene.canvas) {
                compositeCtx.clearRect(0, 0, compositeCanvas.width, compositeCanvas.height);
                compositeCtx.drawImage(video, 0, 0, compositeCanvas.width, compositeCanvas.height);
                compositeCtx.drawImage(scene.canvas, 0, 0, compositeCanvas.width, compositeCanvas.height);
            }
            animationFrameIdRef.current = requestAnimationFrame(draw);
        };

        draw(); // Start the drawing loop

    };

    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        cancelAnimationFrame(animationFrameIdRef.current); // Stop the drawing loop

        let options
        if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
            options = { mimeType: 'video/webm; codecs=vp9' };
        } else if (MediaRecorder.isTypeSupported('video/webm')) {
            options = { mimeType: 'video/webm' };
        } else if (MediaRecorder.isTypeSupported('video/mp4')) {
            options = { mimeType: 'video/mp4', videoBitsPerSecond: 100000 };
        } else {
            console.error("no suitable mimetype found for this device");
        }
        // Create a blob from the recorded data
        const recordedBlob = new Blob(recordedBlobsRef.current, { type: options?.mimeType });

        // Example: Download the recorded video
        const url = URL.createObjectURL(recordedBlob);
        console.log(url, "URL", recordedBlobsRef.current)
        setVideoUrl(url)
        setShowPreviewModal(true);
        // compositeCanvasRef.current = null;
        // recordedBlobsRef.current = []; // Reset the recorded blobs for the next recording
        // animationFrameIdRef.current = null
        // console.log("MediaRecorder state after stopping:", mediaRecorderRef.current.state);
        // mediaRecorderRef.current = null
    };

    const handleButtonPress = () => {
        // Record the start time of the press
        const newTimer = setTimeout(() => {
            console.log('Start recording video...');
            startRecording();
            setIsRecordingVideo(true);
            // Implement the logic to start video recording here
        }, 500);
        setTimer(newTimer);
    };

    const handleButtonRelease = () => {
        // Calculate the duration of the press
        clearTimeout(timer); // Clear the timer on release
        if (!isRecordingVideo) {
            // If not already recording, it means button was released before 500ms
            console.log('Take a photo');
            // Implement the logic to take a photo here
            takeScreenshot();
        } else {
            // If recording, stop the video
            console.log('Stop recording video...');
            stopRecording();
            setIsRecordingVideo(false);
            // Implement the logic to stop video recording here
        }
    };

    return <VStack style={{ position: 'fixed', zIndex: 100, bottom: "50px", left: "50%", transform: "translateX(-50%)" }}>
        <IconButton
            onMouseDown={handleButtonPress}
            onMouseUp={handleButtonRelease}
            onTouchStart={handleButtonPress}
            onTouchEnd={handleButtonRelease}
            aria-label={isRecordingVideo ? 'Recording Video' : 'Take Photo'}
            icon={<MdMovieCreation color='#FFA3FB' />}
            borderRadius="full"
            size="lg"
            bg={isRecordingVideo ? "red.500" : '#191825'}
            color="white"
            _hover={{
                bg: isRecordingVideo ? "red.600" : "blue.600",
            }}
            _active={{
                bg: isRecordingVideo ? "red.700" : "blue.700",
            }}
        />
        <Timer isRecording={isRecordingVideo} />
        {/* <Button
            onClick={takeScreenshot}
        >
            Photo
        </Button>
        <Button onClick={handleRecordClick} >
            {isRecording ? 'Stop Video' : 'Start Video'}
        </Button> */}
        <PreviewModal />
    </VStack>
}