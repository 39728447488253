import { create } from 'zustand';

type State = {
    isRecording: boolean;
    showPreviewModal: boolean;
    imageUrl: string;
    videoUrl: string;
};

type Actions = {
    toggleRecording: () => void;
    setShowPreviewModal: (show: boolean) => void;
    setImageUrl: (url: string) => void;
    setVideoUrl: (url: string) => void;
    resetMediaUrls: () => void;
};

export const useMediaCaptureStore = create<State & Actions>((set) => ({
    // Initial state
    isRecording: false,
    showPreviewModal: false,
    imageUrl: '',
    videoUrl: '',

    // Actions
    toggleRecording: () => set((state) => ({ isRecording: !state.isRecording })),
    setShowPreviewModal: (show) => {
        if(!show){
            return set({
                showPreviewModal: false,
                imageUrl: "",
                videoUrl: ""
            })
        }
        set({ showPreviewModal: show })
    },
    setImageUrl: (url) => set(() => ({ imageUrl: url })),
    setVideoUrl: (url) => set(() => ({ videoUrl: url })),
    resetMediaUrls: () => set(() => ({ imageUrl: '', videoUrl: '' })),
}));
