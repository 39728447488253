import { Modal, ModalContent, ModalOverlay, VStack, Text, ModalHeader, ModalFooter, Image, ModalCloseButton, ModalBody, Link } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useMediaCaptureStore } from "../../../../stores/media-capture-store";

export default function PreviewModal() {
    const setShowPreviewModal = useMediaCaptureStore(state => state.setShowPreviewModal);
    const showModal = useMediaCaptureStore((state) => state.showPreviewModal);
    const { videoUrl, imageUrl } = useMediaCaptureStore((state) => ({ videoUrl: state.videoUrl, imageUrl: state.imageUrl }));
    const [sharableFile, setSharableFile] = useState<File | null>(null);
    const [canShare, setCanShare] = useState(false);

    useEffect(() => {
        const urlToFetch = videoUrl || imageUrl;
        const fileType = videoUrl ? "video/mp4" : "image/png";
        const fileName = videoUrl ? "video.mp4" : "image.png";

        if (urlToFetch) {
            fetch(urlToFetch)
                .then((response) => response.blob())
                .then((blob) => {
                    const sharableFiles = [new File([blob], fileName, { type: fileType })];
                    const canShare = navigator.canShare?.({ files: sharableFiles });
                    setCanShare(!!canShare);
                    if (canShare) {
                        setSharableFile(sharableFiles[0]);
                    }
                })
                .catch(() => {
                    setCanShare(false);
                });
        }
    }, [videoUrl, imageUrl]);

    const linkProps: any = {};

    if (canShare) {
        linkProps.onClick = async () => {
            try {
                navigator.share({ files: [sharableFile!] });
            } catch (e) {
                // Handle share error
            }
        };
    } else {
        linkProps.href = videoUrl ? videoUrl : imageUrl;
        linkProps.download = videoUrl ? 'ar-composite-recording' : "relive-ar-screenshot";
    }

    return showModal && (
        <Modal isOpen={showModal} onClose={() => setShowPreviewModal(false)} isCentered>
            <ModalOverlay bg="rgba(0, 0, 0, 0.6)">
                <ModalContent
                    bg="#191825"
                    borderRadius="20px"
                    boxShadow="none"
                    maxWidth="90vw"
                    maxHeight="80vh"
                >
                    <ModalHeader color="white">Save media</ModalHeader>
                    <ModalCloseButton color="white" onClick={() => setShowPreviewModal(false)} />
                    <ModalBody>
                        <VStack alignItems="center" justifyContent="center" gap="20px">
                            {imageUrl && <Image src={imageUrl} maxW="100%" maxH="100%" />}
                            {videoUrl && <video src={videoUrl} controls preload="metadata" style={{ maxWidth: '100%', maxHeight: '100%' }} />}
                            <Link
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                width="220px"
                                height="54px"
                                borderRadius="20px"
                                padding="14px 0px"
                                fontSize="22px"
                                fontWeight="700"
                                lineHeight="22px"
                                color="#000"
                                background="#FFA3FB"
                                _hover={{ background: '#FFA3FB', color: '#000' }}
                                {...linkProps}
                            >
                                Save
                            </Link>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        {/* <Text color="white">Scan the qrCode to preview AR in the relive ar app.</Text> */}
                    </ModalFooter>
                </ModalContent>
            </ModalOverlay>
        </Modal>
    );
}
