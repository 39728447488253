'use client';

import { Box, Button, Center, HStack, IconButton, useDisclosure, keyframes } from '@chakra-ui/react';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStore } from '../../../../stores/AppStore';
import CanvasScanningStates from '../../../../components/CanvasScanningStates';
import { Link, useNavigate } from 'react-router-dom';

// Define keyframes for jittering animation
const jitter = keyframes`
  0% { transform: translateY(0); }
  25% { transform: translateY(-2px); }
  50% { transform: translateY(2px); }
  75% { transform: translateY(-2px); }
  100% { transform: translateY(0); }
`;

export default function ArViewerControls() {
    const { t } = useTranslation('app');
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure(); // useDisclosure hook for handling the collapse state
    const touchStartY = useRef(0);
    const touchEndY = useRef(0);

    const handleRedirect = () => {
        useAppStore.getState().reset();
        navigate("/");
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        touchStartY.current = e.touches[0].clientY;
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        touchEndY.current = e.touches[0].clientY;
    };

    const handleTouchEnd = () => {
        const swipeDistance = touchStartY.current - touchEndY.current;
        if (swipeDistance > 25) {
            // Swipe up detected
            onOpen();
        } else if (swipeDistance < -25) {
            // Swipe down detected
            onClose();
        }
    };

    return (
        <>
            {!isOpen && (
                <Center
                    position="fixed"
                    bottom={6}
                    width="100vw"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    <IconButton
                        icon={<ChevronUpIcon boxSize={6} color='#FFA3FB' />}
                        onClick={onOpen}
                        animation={`${jitter} 0.5s infinite`}
                        background='none'
                        border='none'
                        cursor='pointer'
                        aria-label="Expand"
                        bg='#191825'
                    />
                </Center>
            )}
            <Box
                bg='#191825'
                borderRadius='30px 30px 0 0'
                position='fixed'
                bottom='-2px'
                width='100%'
                height={isOpen ? '79px' : '0px'}
                overflow='hidden'
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                padding={isOpen ? '10px' : '0'}
                transition='height 0.3s ease, padding 0.3s ease'
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                {isOpen && (
                    <>
                        <CanvasScanningStates />
                        <HStack alignItems='center' justifyContent='center' width='100%' height='100%' spacing={4}>
                            <Button
                                as={Link}
                                color='#FFA3FB'
                                bg='#FFA3FB33'
                                onClick={handleRedirect}
                                borderRadius="10px"
                                px={8} // padding inline (left and right)
                                py={2} // padding block (top and bottom)
                                fontSize="16px"
                                fontWeight="600"
                                lineHeight="22px"
                                justifyContent="center" // centers children horizontally
                                alignItems="center" // centers children vertically
                            >
                                {t('viewer.exitApp')}
                            </Button>
                            <IconButton
                                icon={<ChevronDownIcon boxSize={6} />}
                                onClick={onClose}
                                background='none'
                                color='#FFA3FB'
                                bg='#FFA3FB33'
                                border='none'
                                cursor='pointer'
                                aria-label="Collapse"
                            />
                        </HStack>
                    </>
                )}
            </Box>
        </>
    );
}
