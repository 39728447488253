 

import { Box, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import TopBanner from './TopBanner';
import SocialIcons from './SocialIcons';


type AppLayoutProps = {
    hasLogo?: boolean;
    hasSocials?: boolean;
    children: ReactNode;
    gridTemplateRows?: string;
}

export default function AppLayout(props: AppLayoutProps) {
    const { hasLogo = true, hasSocials = true, children, gridTemplateRows } = props;
    return (
        <Box
            justifyContent='center'
            flexDirection='column'
            bg='#191825'
            height='100%'
            position='fixed'
            top='0px'
            left='0px'
            right='0px'
            bottom='0px'
            textAlign='center'
        >
            <VStack height='100%' display='grid' gridTemplateRows={gridTemplateRows}>
                {hasLogo && <TopBanner />}
                {children}
                {hasSocials && <SocialIcons />}
            </VStack>
        </Box>
    );
}
