 

import { Text } from "@chakra-ui/react";
import { useEffect, useState } from "react"

export default function Timer({ isRecording }: any) {
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        let intervalId
        // Function to update the duration
        const updateDuration = () => {
            setDuration(prevDuration => prevDuration + 1);
        };

        if (isRecording) {
            // Start the timer by setting an interval
             intervalId = setInterval(updateDuration, 100); // Update duration every second
        } else {
            // Clear the timer and reset duration if recording stops
            if (intervalId) {
                clearInterval(intervalId);
            }
            setDuration(0);
        }

        // Cleanup function to clear the timer if the component unmounts or if isRecording changes
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isRecording]); // Dependency array, effect runs when `isRecording` changes

    return <Text color={"white"} visibility={duration > 0 ? "visible" : "hidden"}>{duration / 10} sec</Text>;
}