import { Box, Button } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import QRCodeScanner from './qr-code-scanner';
import { useQrScannerStore } from '../../stores/QrScannerStore';
import { QrCodeScannerStates } from '../../model-types/AppModels';
import ScanningStatusBadge from '../../components/ScanningStatusBadge';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export default function Scanning() {
    const { t } = useTranslation('app', { "keyPrefix": "home" });
    const navigate = useNavigate();
    const setScanningState = useQrScannerStore((state) => state.setScanningState);
    const scanningState = useQrScannerStore((state) => state.scanningState);

    useEffect(() => {
        if (scanningState === QrCodeScannerStates.FOUND) {
            return;
        }
        const timeout = setTimeout(() => {
            setScanningState(QrCodeScannerStates.SEARCHING_WITH_HINT);
        }, 3000);

        return () => {
            // clears timeout before running the new effect
            clearTimeout(timeout);
        };
    }, [scanningState]);

    return (
        <>
            <Box
                position="absolute"
                top={0}
                left={0}
                height={'100vh'}
                width={'100vw'}
                bg='#191825'
            >
                <QRCodeScanner />
                <Box
                    bg='#191825'
                    borderRadius='30px 30px 0 0'
                    position='fixed'
                    bottom='-2px'
                    width='100%'
                    height='79px'
                    display='flex'
                    flexDirection='row'
                    justifyContent='center'
                    gap='50px'
                    padding='25px'
                >
                    {scanningState === QrCodeScannerStates.FOUND
                        && <ScanningStatusBadge backgroundColor='#B5FF7A' type={QrCodeScannerStates.FOUND} position={'fixed'} />}
                    {scanningState === QrCodeScannerStates.SEARCHING
                        && <ScanningStatusBadge backgroundColor='#FFA3FB' type={QrCodeScannerStates.SEARCHING} position={'fixed'} />}
                    {scanningState === QrCodeScannerStates.SEARCHING_WITH_HINT
                        && <>
                            <Box position='fixed' display='flex' flexDirection='column' gap='10px'>
                                <ScanningStatusBadge
                                    backgroundColor='#473450'
                                    color={'#FFA3FB'}
                                    type={QrCodeScannerStates.SEARCHING_WITH_HINT}
                                    position={'relative'}
                                />
                                <ScanningStatusBadge backgroundColor='#FFA3FB' type={QrCodeScannerStates.SEARCHING} position={'relative'} />
                            </Box>
                        </>}
                    {/* <Button onClick={() => setIsScanning(false)} */}
                    <Button
                        color='#FFA3FB'
                        onClick={() => navigate('/')}
                        bg='#FFA3FB33'
                        borderRadius="10px"
                        px={8} // padding inline (left and right)
                        py={2} // padding block (top and bottom)
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="22px"
                        justifyContent="center" // centers children horizontally
                        alignItems="center" // centers children vertically
                    >
                        {t('exitScanner')}
                    </Button>
                </Box>
            </Box>
        </>
    );
};
