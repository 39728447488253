import { ChakraProvider } from "@chakra-ui/react";
import { PostHogProvider } from 'posthog-js/react';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import { ApiClientProvider } from "./components/ApiClientProvider";
import { BrandingWrapper } from "./components/BrandingWrapper";
import PosthogCapture from "./components/posthog-capture";
import ArPage from "./routes/ar/page";
import HomeScreen from "./routes/home/page";
import Scanning from "./routes/qr-code-scanner/page";
import { Fonts, theme } from "./theme/global-theme";


const options = {
    api_host: "https://us.i.posthog.com"
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <HomeScreen />,
    },
    {
        path: "/scan-code",
        element: <Scanning />
    },
    {
        path: "/ar/:arAppId",
        element: <ArPage />
    },
]);


export default function App() {
    return (

        <ChakraProvider resetCSS={false} theme={theme}>
            <Fonts />
            <PostHogProvider
                apiKey={"phc_RjvCauP8ncCkIg7GzbV9Mlv7cJGh8cSCWalfAo9sDJW"}
                options={options}
            >
                <ApiClientProvider>
                    <PosthogCapture />
                    <RouterProvider router={router} />
                    <BrandingWrapper>
                        <div></div>
                    </BrandingWrapper>
                </ApiClientProvider>
            </PostHogProvider>

        </ChakraProvider>
    )
}