export default {
    home: {
        heading: 'Welcome to',
        headingName: 'Relive AR',
        scanCode: 'Scan Code',
        orderText: 'If you don’t have your Magic product yet. you can order one',
        orderLink: 'here',
        addToHomeText: "To add this website to your home screen, tap the share button and select 'Add to Home Screen'.",
        addToHomeButton: 'Add to Home Screen',
        qrCodeScanningStates: {
            searching: 'LOOKING FOR QR CODE...',
            searchingWithHint: 'MOVE CLOSER',
            found: 'QR CODE FOUND',
        },
        exitScanner: 'Exit Scanner',
    },
    selectEffect: {
        heading: 'Select Animation',
        effects: {
            hearts: 'Hearts',
            butterflies: 'Butterflies',
            sparks: 'Sparks',
            empty: 'Empty',
        },
    },
    startButton: {
        text: 'Start',
    },
    viewer: {
        exitApp: 'Exit App',
        canvasDetectionStates: {
            searching: 'Looking for Target',
            searchingWithHint: 'Capture Entire Target',
            found: 'Target FOUND',
            empty: 'EMPTY',
        },
    },
} as const;
