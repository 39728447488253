import React from "react"
import StartPage from "./@start/page";
import Arify from "./@viewer/page";
import { useAppStore } from "../../../stores/AppStore";
import { useLocation } from "react-router-dom";
import posthog from 'posthog-js'; // new


export default function ArifyPage({ arApps }: any) {
    let location = useLocation(); // new

    React.useEffect(() => { // new
        posthog.capture('$pageview')
    }, [location]);

    const showArViewer = useAppStore((state) => state.showArViewer);

    return !showArViewer ? <StartPage arApps={arApps} /> : <Arify arApps={arApps} />
}
