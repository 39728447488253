'use client';

import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useCanvasScannerStore } from '../stores/CanvasScannerStore';
import { useAppStore } from '../stores/AppStore';
import { CanvasDetectionStates } from '../model-types/AppModels';
import ScanningStatusBadge from './ScanningStatusBadge';


export default function CanvasScanningStates() {
    const isLoading = useAppStore((state) => state.isLoading);
    const setScanningState = useCanvasScannerStore((state) => state.setScanningState);
    const scanningState = useCanvasScannerStore((state) => state.scanningState);

    useEffect(() => {
        if (isLoading) {
            return;
        }
        if (scanningState === CanvasDetectionStates.FOUND) {
            const fadeTime = setTimeout(() => {
                setScanningState(CanvasDetectionStates.EMPTY);
            }, 2000);
            return () => clearTimeout(fadeTime);
        }

        const timeout = setTimeout(() => {
            if (scanningState === CanvasDetectionStates.SEARCHING) {
                setScanningState(CanvasDetectionStates.SEARCHING_WITH_HINT);
            }
        }, 3000);

        return () => {
            // clears timeout before running the new effect
            clearTimeout(timeout);
        };
    }, [scanningState, isLoading]);

    return <>
        {scanningState === CanvasDetectionStates.FOUND
            && <ScanningStatusBadge backgroundColor='#B5FF7A' position={'fixed'} type={CanvasDetectionStates.FOUND} />}
        {scanningState === CanvasDetectionStates.SEARCHING
            && <ScanningStatusBadge backgroundColor='#FFA3FB' position={'fixed'} type={CanvasDetectionStates.SEARCHING} />}
        {scanningState === CanvasDetectionStates.SEARCHING_WITH_HINT
            && <>
                <Box position='fixed' display='flex' flexDirection='column' gap='10px' zIndex='-2'>
                    <ScanningStatusBadge
                        backgroundColor='#473450'
                        color={'#FFA3FB'}
                        type={CanvasDetectionStates.SEARCHING_WITH_HINT}
                        position={'relative'}

                    />
                    <ScanningStatusBadge
                        backgroundColor='#FFA3FB'
                        position={'relative'}
                        type={CanvasDetectionStates.SEARCHING}
                    />
                </Box>
            </>}
    </>;
}
